.orders {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
    row-gap: 5px;
    box-shadow: var(--main-box-shadow);
    border-radius: var(--main-radius);
    padding: 15px;
}

.orders-menu-btn {
    padding: 5px 15px 7px;
    font-family: Montserrat, sans-serif;
    background-color: var(--color-2);
    font-size: 13px;
    height: auto;
    max-height: 27px;
    font-weight: 500;
    color: #fff0ff;
    border: none;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    opacity: 0.75;
}

.orders-menu-btn:hover {
    opacity: 1;
}

.order-item {
    color: #4b4b4b;
    width: auto;
    height: auto;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
    border-radius: 2px;

    overflow: hidden;
    margin-bottom: 15px;
}

.order-product .title-link {
    font-size: 20px;
    color: black;
}

.order-product .title-link:hover {
    color: #00a9ce;
}

.order-item-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    box-sizing: border-box;
    justify-content: space-between;
}


.order-column {
    width: 100%;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 30px;
    gap: 15px;
}

.order-column-item {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    box-sizing: border-box;
    font-size: 15px;
}

.order-column.active {
    padding: 15px;
    background-color: #efefef;
    display: flex;
    font-size: 16px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.order-column-item span {
    font-weight: 500;
}

.order-container .btn {
    padding: 5px 10px;
    margin: 0;
    height: auto;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    opacity: 75%;
    background-color: #e7e7e7;
    color: #000000;
    gap: 10px;
}

.order-container .btn:hover {
    opacity: 100%;
}


/* OrderProduct page */

.order-products {
    width: 100%;
    max-width: 1200px;
    visibility: hidden;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    box-shadow: 0 0 10px #ececec;
    row-gap: 20px;
    border-radius: 15px;
    height: 0;
    transition: opacity 0.3s ease-in;
}

.order-products.active {
    visibility: visible;
    height: auto;
    opacity: 1;
    margin: 2%;
    padding: 1% 0;
}

.order-product {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: start;
    margin: 0 20px;
    gap: 20px;
    box-sizing: border-box;
    padding-bottom: 20px;
    border-bottom: 1px solid #dedede;
    font-size: 12px;
    font-weight: 400;
}

.order-products .order-product img {
    border-radius: 15px;
    width: 30%;
    max-width: 300px;
    min-width: 150px;

}

.order-product-info {
    width: 100%;
    padding: 5px 20px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
}

.order-product-info span {
    padding-left: 5px;
    font-weight: 500;
    color: black;
}

.edit-profile-icon {
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.edit-profile-icon:hover {
    opacity: 1;
}

/* personal account page */

.account-page {
    position: relative;
    width: 100%;
}


.account-info {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
}


.edit-input {
    background-color: #ffffff;
    height: 10px;
    text-decoration: none;
    border: none;
    padding: 5px;
    font-size: 14px;
}


.account-block {
    box-shadow: var(--main-box-shadow);
    border-radius: var(--main-radius);
    width: auto;
    min-width: 300px;
    height: auto;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 10px;

    & .title {
        font-weight: 400;
        font-size: 20px;
        margin-bottom: 15px;
        font-family: var(--font-1), sans-serif;
        height: 30px;
    }

    & .info-block {
        display: flex;
        width: auto;
        margin: 0;
        align-items: center;
        align-content: center;
        row-gap: 10px;

        & input {
            width: auto;
            height: auto;
            padding: 5px 10px;
            font-size: 14px;
            background-color: #f1f1f1;
            border-radius: 5px;
            color: #2d2d2d;
            border: 1px solid white;
            font-family: "Sofia Sans Semi Condensed", sans-serif;
        }
    }

    & .btn-block {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;

        & button {
            padding: 3px 15px 5px;
            width: auto;
            border-radius: 2px;
            box-shadow: none;
            border: none;
            font-size: 12px;
            background-color: var(--color-2);
            color: white;
            cursor: pointer;

            &:hover {
                border: none;
            }
        }
    }
}


.account-block .info {
    position: relative;
    align-items: center;
    align-content: center;
    width: 100%;
    height: auto;
    padding-left: 15px;
    color: #2d2d2d;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.account-block .info-block .info .label {
    width: min-content;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    user-select: none;
}



.refresh-btn {
    background-color: var(--color-2);
    font-size: 12px;
    font-weight: 500;
    color: white;
    width: max-content;
    height: auto;
    margin: 30px 20px 15px 15px;
    padding: 6px 10px 7px;
    border-radius: 3px;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
        opacity: 1;
    }
}

.account-block .description {
    color: #5e5e5e;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    padding: 20px 15px;
    font-family: Monospaced, sans-serif;

    border-radius: 10px;
}

.account-block .form-group {
    margin: 7px 0;
}

.account-block .form-group .form-label {
    font-size: 14px;
    margin-bottom: 5px;
}

.account-block .form-group .form-control {
    font-size: 14px;
    height: 14px;
    border-bottom: none;
    background-color: #f8f8f8;
}


.admin-btn {
    width: auto;
    position: relative;
    padding: 7px 14px;
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-family: var(--font-1), sans-serif;
    gap: 5px;

    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 5px;
    background-image: linear-gradient(to right, #507de2 0%, #6199f4 51%, #507de2 100%);

    &:hover {
        background-position: right center;
    }
}


.order-container {
    width: 100%;
    height: auto;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
}

.order-container .order-products-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    line-height: 25px;
    margin-bottom: 15px;
    border-radius: var(--main-radius);
    box-shadow: var(--main-box-shadow);
}


.order-container .order-products-container-2 {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    gap: 10px;

}

.order-container .order-products-container span {
    font-weight: 500;
}

.order-container .order-products-container .order-products-item {
    width: 100%;
    margin-left: 20px;
    display: flex;
    justify-content: flex-start;
    column-gap: 20px;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    line-height: 16px;
}

.order-products-item .value {
    background-color: #363636;
    padding: 3px 10px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;

}

.settings-page {
    width: 100%;
    height: auto;
    min-height: 100px;
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 20px;
    box-sizing: border-box;
}

.site-data-item {
    width: auto;
    max-width: 500px;
    margin: auto;
    height: auto;
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    gap: 10px;
}
.site-data-item div {
    width: auto;
    padding: 10px 20px;
    border: 1px solid grey;
    margin-bottom: 10px;
    border-radius: 3px;
}

.site-data-item input {
    width: auto;
    max-width: 40px;
    padding: 10px 20px;
    border: 1px solid grey;
    margin-bottom: 10px;
    border-radius: 3px;
}

.site-data-item button {
    max-width: 250px;
    width: auto;
    padding: 10px 20px;
    height: auto;
    box-sizing: border-box;
    margin-bottom: 10px;
    cursor: pointer;
}


@media (max-width: 768px) {
    .account-block {
        padding: 5px;
        box-shadow: none;
        background-color: var(--mobile-background-color);
    }

    .order-column.active {
        font-size: 12px;
    }

    .order-column-item {
        font-size: 12px;
    }

    .order-column {
        padding: 15px;
    }
}