@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wdth,wght@0,62.5..100,100..900;1,62.5..100,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans+Condensed:ital,wght@0,1..1000;1,1..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans+Semi+Condensed:ital,wght@0,1..1000;1,1..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
     margin: 0;
     padding: 0;
 }

 body {
     color: #181818;
     font-weight: 300;
     width: 100%;
 }


 html {
     overflow-x: hidden;
 }

.wrapper {
    position: relative;
    user-select: none;
    width: 100%;
    box-sizing: border-box;
}

.wrapper main {
    background-color: #ffffff;
    max-width: 1200px;
    min-width: 300px;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    display: flex;
    margin: 120px auto 0;
    padding: 0 0 50px 0;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    align-content: flex-start;
    min-height: 90vh;
    font-family: "Sofia Sans Semi Condensed", sans-serif;
    font-weight: 300;
}

:root {
    --color-primary: #235ad1;
    --main-radius: 5px;
    --main-box-shadow: 0 0 15px #f1f1f1;
    --light-box-shadow: 0 0 5px #f5f5f5;
    --mobile-background-color: #fafafa;
    --color-1: #b8d9be;
    --color-2: #88af8f;
    --color-2-dark: #79ab8c;
    --color-2-light: rgba(154, 197, 160, 0.75);
    --color-3: #d375dc;
    --color-3-dark: rgba(45, 45, 45, 0.84);
    --color-3-light: rgba(211, 117, 220, 0.75);
    --color-4: #9495a4;
    --color-5: rgba(63, 63, 63, 0.6);
    --color-5-dark: rgba(190, 190, 190, 0.6);
    --color-1-textColor: rgba(37, 101, 73, 0.81);
    --font-1: Fira Sans;
    --font-2: Sofia Sans Semi Condensed;
}

.server-unavailable {
    width: 100%;
    max-width: 1200px;
    min-width: 300px;
    margin: auto;
}

.error-container {
    width: 100%;
    height: auto;
    margin: 10% auto;
    max-width: 700px;
    min-width: 300px;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 2%;
    background-color: #f5f5f5;
    font-family: Monospaced, sans-serif;
    font-size: 12px;
}


.order-status-indicator {
    background-color: rgba(121, 121, 121, 0.7);
    padding: 4px 15px;
    width: max-content;
    color: white;
    font-size: 12px;
    font-weight: 600;
    border-radius: 3px;
}

@media (max-width: 768px) {
    .wrapper main {
        margin: 5px auto 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
}