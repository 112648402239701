.search-panel {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    background-color: #f6f6f6;
    border-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 8px 15px;
}


.search-panel .search-input {
    padding: 0 5px;
    height: auto;
    width: 100%;
    font-size: 14px;
    border: none;
    color: #696969;
    background-color: #f6f6f6;

}
.search-panel .search-btn {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px 7px;
    opacity: 0.7;
    background-color: #eaeaea;
    transition: all 0.3s ease-in-out;
    border-radius: 13px;
    color: rgba(44, 44, 44, 0.64);
    cursor: pointer;
}

.search-panel .search-btn:hover {
    opacity: 1;
}

@media (max-width: 768px) {
    .search-panel {
        padding: 6px 10px;

    }

    .search-panel .search-input {
        font-size: 12px;
    }

}