
.item {
    text-decoration: none;
    position: relative;
    width: 280px;
    height: 510px;
    font-weight: 300;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    flex-wrap: wrap;
}


.img-box {
    width: 100%;
    height: 350px;
    display: flex;
    align-content: center;
    background-color: #f6f6f6;
    border-radius: 2px;
    overflow: hidden;
    justify-content: center;
    padding: 10px;
    position: relative;
}

.item .img-box img {
    position: relative;
    width: 100%;
    height: auto;
    object-fit: scale-down;
    transition: all 0.2s ease-in-out;
}

@keyframes slideme {
    0% {
        left: -30px;
        margin-left: 0px;
    }
    30% {
        left: 110%;
        margin-left: 80px;
    }
    100% {
        left: 110%;
        margin-left: 80px;
    }
}

.favorite-btn {
    box-sizing: border-box;
    color: var(--color-2);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: auto;
    cursor: pointer;
    padding: 6px 8px;
    border-radius: 5px;
    background-color: rgba(239, 239, 239, 0.63);
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: rgba(223, 223, 223, 0.76);
        box-shadow: 0 0 3px rgba(223, 223, 223, 0.76);
    }
}

.item-info .add-to-cart {
    width: auto;
    height: auto;
    padding: 7px 10px;
    color: white;
    background-color: var(--color-2-dark);
    border-radius: 5px;
    font-family: var(--font-1), sans-serif;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    transition: opacity 0.3s ease-in-out;

    overflow: hidden;
    position: relative;
    box-sizing: border-box;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -200px;
        width: 60px;
        height: 100px;
        background-color: #fff;
        filter: blur(30px);
        transform: skewX(30deg) translateZ(0);
        transition: 1s;
        animation-name: slideme;
        animation-duration: 3s;
        animation-delay: 0.05s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
    }

    &:hover {
        box-shadow: 0 0 5px #9cc5ab;
    }
}


.item-info .add-to-cart.active {
    color: var(--color-2-dark);
    background-color: white;
    padding: 6px 10px;
    border: 2px solid var(--color-2-dark);
    box-shadow: none;

}

.item-info {
    position: relative;
    color: #727272;
    height: 190px;
    padding: 10px 5px;
    overflow: hidden;
    width: 100%;
    font-weight: 600;
    box-sizing: border-box;
    transition: transform 0.5s ease-in-out;
}



.item-info .price {
    color: rgba(0, 0, 0, 0.73);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-family: "Barlow", sans-serif;
    column-gap: 15px;
    font-size: 16px;
    margin-bottom: 5px;
    align-items: flex-start;
}

.item-info .title{
    position: relative;
    display: flex;
    width: 100%;
    min-height: 40px;
    height: max-content;
    word-break: break-word;
    color: rgba(58, 58, 58, 0.86);
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 10px;
}

.item-info .title:hover {
    color: #03657e;
}



.in-stock, .out-stock, .size {
    font-size: 12px;
    font-weight: 700;
    margin: 10px 0;
    color: black;
    top: 10px;
    right: 15px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 5px rgba(168, 168, 168, 0.82);
    padding: 1px 4px;
    border-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    align-items: center;
}

.item .size {
    top: 35px;

    padding: 1px 10px;
    color: #7a2b86;
}

.discount {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: 5px;
    border-radius: 6px;
    background-color: rgba(236, 17, 17, 0.87);
    padding: 2px 5px;
    font-size: 12px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.87);

}

.in-stock {
    color: rgba(55, 100, 71, 0.92);
}

.out-stock {
    color: #916605;
}

@media (max-width: 768px) {
    .item {
        padding: 0;
        width: 47%;
        height: auto;
        border-radius: 0;
    }

    .in-stock, .out-stock {
        right: 10px;
        top: 28px;
        font-size: 8px;
    }

    .item .img-box {
        height: 220px;
        padding: 5px;
    }

    .item-info {
        height: auto;
    }

    .item .size {
        font-size: 8px;
        top: 47px;
        left: auto;
        right: 10px;
    }

    .item-info .title {
        font-size: 12px;
    }

    .discount {
        font-size: 10px;
        width: auto;
        font-weight: 600;
        padding: 1px 3px;
    }

    .item-info .price {
        column-gap: 0;
        font-size: 14px;
    }

    .item-info .add-to-cart {
        font-size: 12px;
        border-width: 1px;
    }
}