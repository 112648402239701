.sort-block {
    width: auto;
    display: flex;
    justify-content: right;
    position: relative;
    height: max-content;
    color: #6b6b6b;
    font-weight: 400;
    border-radius: var(--main-radius);
    padding: 7px 15px 8px;
    margin-top: 10px;
    background-color: #f6f6f6;
    font-family: var(--font-1), sans-serif;

}

.button-sort {
    position: relative;
    right: 0;
    height: auto;
    padding: 0 5px;

    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    align-items: center;
}

.mobile-button-sort {
    width: auto;
}
.sort-block .add-to-cart {
    margin-bottom: 0;
    width: max-content;
}

:root {
    --clear-button-icon-pad: 2px;
    --clear-button-icon-width: 10px;

    --icon-color: #575757;
}

.rdn {
    position: relative;
}

.rdn-control {
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    font-size: 14px;
    text-decoration: none;
    color: #6b6b6b;
    width: max-content;
    margin-left: 0;
    box-sizing: border-box;
    outline: none;
    transition: all 200ms ease;
    cursor: pointer;

}


.rdn-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.rdn-control-icon {
    display: block;
    height: 0;
    margin-top: -6px;
    position: absolute;
    right: 10px;
    top: 14px;
}

.rdn-control-clear {
    position: absolute;
    top: 8px;
    right: 30px;
    font-size: 0;
    cursor: pointer;
}

.rdn-control-clear-button {
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: transparent;
    position:relative;
    width: calc(var(--clear-button-icon-width) * 2);
    height: calc(var(--clear-button-icon-width) * 2);
    cursor: pointer;
}

.rdn-control-clear-button-icon {
    position: absolute;
    left: 3px;
    top: 3px;

    width: var(--clear-button-icon-width);
    height: var(--clear-button-icon-width);
}

.rdn-control-clear-button-icon:before,
.rdn-control-clear-button-icon:after {
    position: absolute;
    left: calc(var(--clear-button-icon-width) / 2 * 1px);
    content: ' ';
    height: calc(var(--clear-button-icon-width) + 3px);
    width: 2px;
    background-color: var(--icon-color);
}

.rdn-control-clear-button-icon:before {
    transform: rotate(45deg);
}

.rdn-control-clear-button-icon:after {
    transform: rotate(-45deg);
}


.rdn-drop,
.rdn-selection {
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    max-height: 200px;
    font-size: 14px;
    overflow-y: auto;
    position: absolute;
    top: 170%;
    right: -15px;
    width: max-content;
    min-width: 60px;
    overflow-x: hidden;
    z-index: 100;
    -webkit-overflow-scrolling: touch;
}

@media (max-width: 768px) {
    .rdn-drop,
    .rdn-selection {
        right: -10px;
        top: 30px;
    }

    .rdn-control {
        font-size: 12px;
    }

    .sort-block {
        padding: 8px 10px;
        font-size: 12px;
    }
}

.rdn-selection {
    position: relative;
    z-index: 100;
}

.rdn-drop-menu-group-title,
.rdn-selection-menu-group-title {
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
}

.rdn-drop-menu-option,
.rdn-drop-menu-group-option,
.rdn-selection-menu-option,
.rdn-selection-menu-group-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;

}

.rdn-drop-menu-option.is-disabled-option,
.rdn-drop-menu-group-option.is-disabled-option,
.rdn-selection-menu-option.is-disabled-option,
.rdn-selection-menu-group-option.is-disabled-option {
    cursor: default;
}

.rdn-drop-menu-option:last-child,
.rdn-drop-menu-group-option:last-child,
.rdn-selection-menu-option:last-child,
.rdn-selection-menu-group-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.rdn-drop-menu-option:hover,
.rdn-drop-menu-group-option:hover,
.rdn-selection-menu-option:hover,
.rdn-selection-menu-group-option:hover {
    background-color: #f2f9fc;
    color: #333;
}

.rdn-drop-menu-option.is-disabled-option:hover,
.rdn-drop-menu-group-option.is-disabled-option:hover,
.rdn-selection-menu-option.is-disabled-option:hover,
.rdn-selection-menu-group-option.is-disabled-option:hover {
    background-color: #fff;
    color: rgba(51, 51, 51, 0.8);
}

.rdn-drop-menu-option.is-selected,
.rdn-drop-menu-group-option.is-selected,
.rdn-selection-menu-option.is-selected,
.rdn-selection-menu-group-option.is-selected {
    background-color: #f2f9fc;
    color: #333;
}

.rdn-drop-menu-noresults,
.rdn-selection-menu-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
}

