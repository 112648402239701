footer {
    width: 100%;
    background: #C2D9C5;
    margin: 30px auto 0;
    padding: 20px 10px;
    height: auto;
    position: absolute;
    font-family: var(--font-1), sans-serif;
}

.mobile-footer {
    width: 100%;
    margin-bottom: 70px;
    padding: 10px;
    height: auto;
    background: #C2D9C5;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    font-family: var(--font-1), sans-serif;

    gap: 10px;
}

.mobile-footer .logo {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    background: #C2D9C5;
    display: flex;
    justify-content: center;
}

.logo img {
    width: 40%;
    height: auto;
    object-fit: cover;
}

.info-block {
    display: flex;
    max-width: 1200px;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: auto;
    margin: auto;
    color: var(--color-1-textColor);
    font-weight: 300;
    padding: 10px 5px;
    align-items: center;
    align-content: space-between;
    justify-content: space-between;

}

footer line {
    margin-bottom: 20px;
}

footer .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    width: auto;
}


.phones-block .links {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    gap: 10px;
}

.phones-block .links a {
    position: relative;
    margin-left: 0;
}

.phones-block .links .icon {
    position: relative;
}

footer .items .column {
    display: grid;
    grid-template-columns: 1fr;
    width: auto;
}

.column .link {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    transition: all 0.3s linear;
    color: var(--color-1-textColor);

    &:hover {
        transform: translateX(5px);
    }

}


footer .logo-block {
    position: relative;
}

.logo-block img {
    width: 250px;
    height: auto;
    object-fit: cover;
}

.phones-block .text {
    padding: 0 20px 5px 10px;
    font-size: 14px;
    font-weight: 400;
}

.phones-block .links {
    padding-left: 10px;
}

.phones-block .links img {
    width: 40px;
    height: auto;

}

.line-block {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    text-align: center;
    gap: 1px;
    height: auto;
    margin-bottom: 5px;
}

.line-block .icon {
    margin-bottom: 7px;
}

.phones-block .links .icon {
    font-size: 20px;
    margin-right: 5px;
    transition: all 0.3s ease-in-out;
}

.phones-block .links .icon:hover {
    transform: scale(1.1);
}


.footer-logo {
    position: absolute;
    background: var(--color-2);

    text-shadow: 0 0 2px #f1f1f1;
    font-size: 32px;
    font-weight: 700;

    top: -34px;
    left: 15px;
    overflow: hidden;
    padding: 10px;
}

.mobile-footer .phones-block {
    width: auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.mobile-footer .phones-block .text {
    font-weight: 400;
    color: var(--color-1-textColor);
}

.mobile-footer .phones-block .links .icon {
    color: var(--color-1-textColor);
}

.mobile-footer .line-block .icon {
    color: var(--color-1-textColor);
}