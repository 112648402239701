.make-img {
    position: relative;
    width: 60%;
    height: auto;
    object-fit: cover;
    filter: brightness(90%);
}

.modal__content .text-block {
    position: relative;
    padding: 15px;
    width: 40%;
    height: auto;
    background-color: rgba(252, 252, 252, 0.67);
}

@media (max-width: 768px) {
    .modal__content .text-block {
        width: 100%;
        max-width: 300px;
    }
}

.text-block .title {
    position: relative;
    width: auto;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color: #494949;
    border-radius: 5px;
    border: 1px solid #6b6b6b;
    padding: 10px;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;

}

.links {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 40px;
    margin-bottom: 10px;
    height: auto;
    padding: 30px 20px;


}

.links a {
    font-family: "Play", sans-serif;
    position: relative;
    height: 25px;
    line-height: 25px;
    font-size: 20px;
    margin-left: 35px;
    color: #414141;
    font-weight: 400;
}

.links a:hover {
    color: #050505;
}

.links .icon {
    position: absolute;
    bottom: 0;
    font-size: 24px;
    color: #363636;
}

.text-img {
    font-family: "Exo 2", sans-serif;
    font-weight: 500;
    position: absolute;
    left: 20px;
    top: 40px;
    font-size: 30px;
    color: rgb(255, 255, 255);
}

.text-img p {
    margin: 20px 0;
}