.add-product-page {
    width: 100%;
    height: auto;
    padding: 20px 30px;
    background-color: #f6f6f6;
    box-sizing: border-box;
    font-weight: 400;
    font-family: var(--font-1), sans-serif;

}

.add-product-page .title {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 500;
}


/* PreviewImages */
.preview-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 20px;
    row-gap: 15px;
}

.preview-container .preview-img {
    width: 45%;
    max-width: 250px;
    height: auto;
    background-color: #f8f8f8;
    border-radius: 2px;
    overflow:hidden;
    position: relative;
    align-items: center;
    align-content: center;
    padding: 10px;
    box-sizing: border-box;

}
.preview-img img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
}

.preview-img .delete-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #b00000;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: rgba(239, 239, 239, 0.62);
    padding: 2px 3px 1px;
    border-radius: 2px;
}

.input__wrapper {
    width: 45%;
    max-width: 250px;
    height: auto;
    position: relative;
    margin: 15px 0;
    text-align: center;
}

.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.input__file-icon-wrapper {
    height: 60px;
    width: 60px;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #fff;
}

.input__file-button-text {
    line-height: 1;
    margin-top: 1px;
    display: flex;
}

.input__file-button {
    width: 100%;
    max-width: 290px;
    height: 60px;
    background: #1bbc9b;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto;
}

.modal-wait-content {
    position: fixed;
    overflow: hidden;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(2, 2, 2, 0.23);
    z-index: 1000;
}

.modal-wait-window {
    margin: 25% auto;
    display: grid;
    grid-template-columns: 1fr;
    background-color: #ffffff;
    box-shadow: 0 0 5px white;
    border-radius: 10px;
    width: 350px;
    box-sizing: border-box;
    padding: 25px 20px;
    height: auto;
    font-size: 18px;
    justify-content: center;
}

.modal-wait-window .info-message {
    height: auto;
    padding: 0;
    margin: 0;

}

.modal-wait-window .rotate-icon {
    width: 25px;
    height: 25px;
    animation: rotate 3s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }
}

.modal-wait-window .close-btn {
    padding: 5px 10px 6px 10px;
    margin-top: 25px;
    border: none;
    background-color: #980098;
    color: white;
    font-weight: 400;
    border-radius: 5px;
    font-size: 14px;
    font-family: "Play", sans-serif;
    opacity: 0.7;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.modal-wait-window .close-btn:hover {
    opacity: 1;
}

/* PricesContainer */

.prices-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    box-sizing: border-box;
    padding: 10px;
    background-color: #ffffff;
}

.prices-container .title {
    font-size: 16px;
    width: 100%;
}

.price-item {
    width: 100%;
    max-width: 360px;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px 0;
}

.column-title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #3d3d3d;
}

.price-item .input-price {
    width: 70px;
    height: 20px;
    padding: 0;
    font-size: 14px;
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    font-weight: 400;
    border-bottom: none;
    background-color: #f1f1f1;
}

.input-price::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input-price,
.input-price:hover,
.input-price:focus {
    appearance: none;
    -moz-appearance: textfield;
}

.edit-form {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    justify-content: end;
}

.edit-form .btn {
    width: 300px;
    margin: 0;
}

.edit-form .form-group input {
    background-color: white;
}

@media (max-width: 768px) {
    .add-product-page {
        padding: 20px 10px;
    }

    .add-product-page .title {
        font-size: 18px;
    }

    .modal-wait-window {
        width: 70%;
        font-size: 14px;
        margin: 70% auto;
    }

    .preview-container .preview-img {
        width: 45%;
        height: auto;
    }

    .input__wrapper {
        & .input__file-icon-wrapper {
            height: 30px;
            width: 30px;
            font-size: 10px;
        }

        & .input__file-button {
            height: 30px;
            font-size: 12px;
        }
    }
}