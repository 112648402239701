.about-title {
    position: relative;
    font-size: 40px;
    height: auto;
    width: auto;
    margin: 0 auto 50px;
    text-align: center;
}

.page-block .data-block {
    justify-content: space-around;
    gap: 40px 20px;
    width: 100%;
}

.data-block .icons-block {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    gap: 10px;
}

.icons-block-item {
    height: auto;
    width: 160px;
}

.icons-block-item-btn {
    font-size: 12px;
    padding: 7px 20px;
    background-color: var(--color-1);
    color: var(--color-1-textColor);
    border-radius: 5px;
    font-weight: 600;
    opacity: 0.75;

    &:hover {
        opacity: 1;
    }
}

.icons-block-item .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    min-height: 35px;

}

.icons-block-item .text-block {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-align: start;
}

.about-item {
    min-width: 300px;
    max-width: 500px;
    width: 100%;
    position: relative;
    color: rgba(0, 0, 0, 0.71);
}


.about-item-title {
    position: relative;
    display: flex;
    height: auto;
    width: auto;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    gap: 10px;
}

.about-item-icon {
    margin-left: 3px;
    color: #385b46;
}

.about-item .text-block {
    font-size: 14px;
    width: auto;
    font-weight: 400;
}

@media (max-width: 768px) {
    .data-block .icons-block {
        justify-content: space-between;
        box-sizing: border-box;
    }

    .about-item-icon {
        width: 25px;
        min-width: 25px;
    }

    .data-block .text-block {
        font-size: 10px;
    }

    .icons-block-item {
        width: 75px;
    }

    .data-block .about-item-title {
        font-size: 12px;
    }

    .icons-block-item .title {
        font-size: 10px;
        word-break: break-word;
        margin-bottom: 0;
        min-height: 35px;
    }

    .icons-block-item .text-block {
        font-size: 10px;
    }

    .icons-block-item-btn {
        font-size: 8px;
        font-weight: 500;
        padding: 5px 10px;
        border-radius: 2px;
    }
}


