.mobile-nav-menu {
    width: 100%;
    position: fixed;
    height: 60px;
    background-color: #ffffff;
    bottom: 0;
    left: 0;
    z-index: 1000;
    font-family: Barlow, sans-serif;
    box-shadow: 0 5px 25px #d8d8d8;
}

.mobile-nav-menu .hidden-menu {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: calc(100vh - 60px);
    height: auto;
    background-color: white;
    overflow-y: scroll;
}

.hidden-menu .hidden-menu-container {
    margin-top: 20px;
    gap: 20px;
    overflow-y: scroll;
    justify-content: center;
    align-content: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
}

.hidden-menu-container .hidden-menu-item {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.hidden-menu-item img {
    position: relative;
    width: 90%;
    height: auto;
    object-fit: cover;
    border-radius: var(--main-radius);
}

.hidden-menu-item .front {
    position: relative;
    transform: rotateX(0deg);
    transition: all 0.3s ease-in-out;
}

.hidden-menu-item .front.active {
    transform: rotateX(90deg);
}

.hidden-menu-item .back {
    position: absolute;
    transform: rotateX(90deg);
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
}

.hidden-menu-item .back img {
    opacity: 0.20;
}

.contacts-menu-container {
    position: absolute;
    top: 40%;
    left: 5%;
    width: 90%;
    height: 60%;
    padding: 10px 40px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;
    gap: 15px;
}

.contacts-menu-item {
    color: black;
}

.back-nav-items {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 10px;
    box-sizing: border-box;
    top: 0;
    left: 5%;
    width: 90%;
    height: auto;
    min-height: 100%;
    overflow: hidden;
    border-radius: var(--main-radius);

    align-content: center;
    gap: 15px;
    justify-content: center;
}

.back-nav-item {
    width: auto;
    font-size: 12px;
    font-weight: 600;
    color: #0b6b4e;
    text-shadow: 0 0 10px #e5e5e5;
    text-align: center;
}


.hidden-menu-item .back.active {
    transform: rotateX(0);
}

.nav-menu-container {
    width: 100%;
    box-sizing: border-box;
    justify-content: space-around;
    display: flex;
    flex-wrap: nowrap;
    padding: 5px 5px;
    align-items: center;
}

.nav-menu-leftPart, .nav-menu-rightPart {
    position: relative;
    width: 50%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    align-content: center;
    gap: 10px;
    border-radius: 20px;
}

.nav-menu-centerPart {
    position: relative;
    box-shadow: 0 0 7px var(--color-2-light);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: var(--color-2);
    display: flex;
    padding: 9px 12px;
    align-content: center;
    justify-content: center;
    color: white;
    box-sizing: border-box;
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;

    &:active {
        opacity: 1;
    }
}

.mobile-nav-item {
    position: relative;
    width: 30px;
    height: 30px;
    color: grey;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    opacity: 0.8;
    transition: background-color 0.2s ease-in-out;
    background-color: #f4f4f4;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;


    & .icon {
        width: 25px;
        height: 25px;
        position: relative;
        color: #717171;
    }

    &:active {
        background-color: #dfdfdf;
    }
}

.mobile-nav-item .cart-item-count {
    height: auto;
    width: 12px;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    color: #ffffff;
    box-sizing: border-box;

    font-weight: 700;
    top: 3px;
    left: calc(50% - 5px);
    font-size: 12px;
    font-family: Play, sans-serif;
}

.left-angle, .right-angel {
    position: absolute;
    z-index: 100;
    height: 45px;
    width: 30px;
    box-sizing: border-box;
    background: linear-gradient(90deg, rgba(229, 229, 229, 0.9), rgba(0, 0, 0, 0) 50%);
    right: -18px;
    top: -1px;
    border-radius: 50% 0 0 50%;
}

.right-angel {
    border-radius: 0 50% 50% 0;
    left: -18px;
    background: linear-gradient(270deg, rgba(229, 229, 229, 0.9), rgba(0, 0, 0, 0) 50%);


}