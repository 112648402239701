.review-container {
    width: 100%;
    max-width: 550px;
    box-sizing: border-box;
    height: auto;
    padding: 30px;
    box-shadow: var(--main-box-shadow);
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;

    & .title {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        color: rgba(73, 73, 73, 0.54);
        justify-content: space-between;
        margin-bottom: 10px;
    }

    & .text {
        width: 100%;
        height: auto;
        font-family: var(--font-1), sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: rgba(80, 80, 80, 0.83);
        margin: 15px 0 30px;
        white-space: pre-wrap;
    }

    & .images {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 15px;

        & img {
            width: 50%;
            max-width: 110px;
            height: auto;
            object-fit: scale-down;
        }
    }

    & .rating {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: nowrap;
        gap: 10px;
        align-items: center;
        justify-content: end;
    }
}

@media (max-width: 768px) {
    .review-container {
        padding: 15px 10px;

        & .images {
            justify-content: space-between;

        }
    }
}