.admin-product {
    width: 365px;
    height: auto;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    box-shadow: 0 0 10px #e8e8e8;
    align-content: space-between;
}

.admin-product span {
    font-weight: 600;
}

.admin-product img {
    width: 48%;
    height: 230px;
    object-fit: cover;
    border-radius: 3px;
}

.admin-product .title {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
}

.admin-product .info {
    font-size: 14px;
    font-weight: 600;
    height: auto;
    padding: 5px;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.admin-product .info span {
    min-width: 120px;
}

.users-title {
    width: 100%;
    background-color: #dbdbdb;
    font-size: 14px;
    font-weight: 500;
    padding: 15px;
    display: grid;
    grid-template-columns: 2fr 3fr 3fr 1fr;
    justify-items: self-start;
}

.admin-user-item {
    padding: 15px;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 2fr 3fr 3fr 1fr;
    justify-items: self-start;
    background-color: #f5f5f5;
    color: #424242;
    width: 100%;
    font-size: 14px;
    height: auto;
    font-family: var(--font-1), sans-serif;
    opacity: 0.75;
    cursor: pointer;
    column-gap: 5px;

    & .val {
        word-break: break-all;
    }

    &:hover {
        opacity: 1;
        color: black;
    }
}

@media (max-width: 768px) {
    .admin-user-item {
        padding: 10px;
        & .val {
            align-content: center;
        }
    }
}