.order-title {
    border-radius: 10px 10px 0 0;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #effff1;
    font-size: 18px;
    color: #484848;
}

@media (max-width: 768px) {
    .order-title {
        font-size: 14px;
        padding: 10px;
    }
}