.common-page {
    width: 100%;
    min-height: 200px;
    margin-top: 10px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.main-block {
    width: 100%;
    min-height: 1280px;
    height: auto;
    padding: 0;
    margin-bottom: 100px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-around;
}

.home-filter-sort-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 1200px;
    min-width: 100%;
    box-sizing: border-box;
    height: auto;
    margin-bottom: 10px;
    justify-content: space-between;
    gap: 45px;

    & .filter-container {
        width: auto;
    }

    & .mobile-filter-container .rdn-drop, .mobile-filter-container .rdn-selection {
        right: auto;
        left: -12px;
        top: 30px;
        min-width: 200px;
    }

    & .mobile-filter-container .rdn-control {
        width: auto;
    }

    & .mobile-filter-container .rdn-control {
        font-size: 14px;
        color: var(--color-1-textColor);
    }
}


.common-btn {
    background-color: var(--color-2);
    color: white;
    font-size: 14px;
    font-weight: 400;
    padding: 7px 14px;
    width: max-content;
    border-radius: 3px;
    font-family: var(--font-1), sans-serif;
    opacity: 0.85;
    cursor: pointer;
    transition: .2s linear;
    box-sizing: border-box;
    text-align: center;

    &:hover {
        box-shadow: #517c59 0 0 0 40px inset;
    }

    &:active {
        background-color: #76a17d;
    }
}

.page-title {
    width: 100%;
    height: 31px;
    text-align: center;
}

.catalog-items {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    min-height: 800px;
    justify-content: space-between;
    justify-items: center;
    box-sizing: border-box;
    gap: 20px;
}

.text-block {
    width: 100%;
    padding: 10px 5px;
    font-size: 16px;
    text-align: justify;
    font-weight: 500;
}

.search-sort-panel {
    width: 100%;
    height: auto;
    max-height: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: nowrap;
    gap: 25px;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .catalog-items {
        justify-content: space-between;
        min-height: auto;
        row-gap: 20px;
    }
}


.page-block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: auto;
}

.page-block img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 25px;
}

.page-block .data-block {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: space-around;
}



.page-block .data-block img {
    position: relative;
    width: auto;
    max-width: 70%;
    margin: auto;
    height: auto;
}

.page-block .title {
    padding: 5px 0;
    color: #444444;
    font-size: 20px;
    font-weight: 400;
}

.empty-msg {
    font-size: 20px;
    font-weight: 500;
    text-shadow: 0 1px 1px grey;
    color: grey;
    text-align: center;
    padding: 50px 10px;
}

.product-container {
    font-weight: 400;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    align-content: space-between;
    height: auto;
    padding: 20px 0 50px 0;
}

.product-container .img-block {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    height: 540px;
    position: relative;
}

.img-block img {
    width: 480px;
    height: 600px;
    object-fit: cover;
    border-radius: 2px;
}

.img-block .preview {
    width: 110px;
    height: 600px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 7px;
    cursor: pointer;
    padding-right: 5px;
    object-fit: cover;
}

.preview img {
    border-radius: 2px;
    width: 100%;
    height: 145px;
    transition: opacity 500ms ease;
}

.preview img .active {
    opacity: 100%;
}

.preview img:hover {
    opacity: 100%;
}

.product-info {
    position: relative;
    width: 50%;
    min-height: 600px;
    box-sizing: border-box;
    height: max-content;
    font-weight: 500;
    color: #707070;
    border-radius: 5px;
    align-content: space-between;
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid #f1f1f1;
    padding: 15px 15px;
}

.product-info .sizes-block {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 25px;
    font-size: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
}

.product-info .sizes-block .sizes {
    width: 100%;
    margin: 0;
    height: auto;
    padding-left: 2%;
    justify-content: flex-start;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
}

.product-info .title {
    font-size: 18px;
    font-weight: 500;
    color: #414141;
    margin-bottom: 20px;
    padding: 5px 0;
    height: auto;
}

.product-info .price {
    margin: 10px 5px;
    width: auto;
    color: #577663;
    font-size: 24px;
    font-weight: 500;
    padding-left: 1%;
    display: flex;
    flex-wrap: nowrap;
    font-family: Barlow, sans-serif;
    gap: 10px;
}

.product-info .product-size {
    font-size: 14px;
    width: min-content;
    padding: 2px 7px;
    border-radius: 5px;
    position: relative;
    border: 2px solid #b6b6b6;
    color: #b6b6b6;
    cursor: pointer;
    font-weight: 600;
}

.product-info .product-size.active {
    border: 2px solid rgba(45, 121, 68, 0.81);
    color: rgba(45, 121, 68, 0.81);
}

.product-info .description {
    position: relative;
    white-space: pre-line;
    height: auto;
    margin-left: 20px;
    font-size: 16px;
    display: flex;
    font-weight: 400;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
    color: #565656;
}

.product-info .additional-info {
    font-size: 16px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 80px;
    justify-content: space-between;
}

.product-info .add-to-cart-btn {
    width: max-content;
    font-size: 16px;
    border-radius: 5px;
    border: 2px solid #79ab8c;
    color: #79ab8c;
    padding: 7px 12px 8px;
    font-family: var(--font-1), sans-serif;
    height: min-content;
    cursor: pointer;
    font-weight: 400;
    gap: 10px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.product-info .add-to-cart-btn.active {
    background-color: #79ab8c;
    color: white;

    &:hover {
        box-shadow: #517c59 0 0 0 40px inset;
        border-color: #517c59;
    }
}

.item-info .rdn-control:hover {
    color: white;
}


/* Product page */

.offered-items-container {
    width: 300%;
    margin: 100px auto;
    height: auto;
    overflow-x: scroll;
    padding: 50px 0;
    display: flex;
    flex-wrap: nowrap;
    gap: 30px;
    border-top: 1px solid #d5d5d5;
}

.page-banner {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 5px;
}

/* product page */


/* registration page */

.register-page {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.register-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    background-color: #f8f8f8;
    padding: 30px;
    width: 100%;
    min-width: 300px;
    max-width: 500px;
    box-sizing: border-box;
}

.private-policy-check {
    margin-top: 40px;
    font-size: 12px;
    color: grey;
}

.map {
    width: 100%;
    height: auto;
}

.contacts-info {
    position: relative;
    width: 100%;
    min-width: 300px;
    height: auto;
    margin-bottom: 30px;
    align-items: flex-start;
    text-align: left;
}

.contacts-info h6 {
    font-weight: 400;
    font-size: 16px;
    margin-top: 30px;
    margin-left: 0;
    color: #606060;
}

.contacts-info h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.contacts-info h5 {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.78);
}

.contacts-info .links {
    padding: 0;
    margin: 10px 0 0 0;
    gap: 10px;
}

.contacts-info .links .link-item {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    color: #444444;
    gap: 10px;
}

.contacts-info .links .link-item .icon {
    position: relative;
    margin-right: 0;
}

.contacts-info .links a {
    position: relative;
    height: auto;
    color: #5e5e5e;
    font-size: 14px;
    padding: 0;
    margin-left: 0;
}

.contacts-info .links a:hover {
    color: black;
}

.edit-btn {
    position: absolute;
    right: 0;
    top: -50px;
    opacity: 0.5;
    color: black;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.edit-btn:hover {
    opacity: 1;
}

.privacy-policy-page {
    width: auto;
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;
    justify-content: center;
    font-family: 'Monospaced', sans-serif;
}

.privacy-policy-page .title {
    margin: 15px auto 35px;
    font-size: 16px;
    font-weight: 600;
}

.privacy-policy-page .text {
    white-space: pre-line;
    width: auto;
    margin: 0 auto 50px;
    min-width: 350px;
    max-width: 700px;
    font-size: 14px;
    text-align: justify;
}

.info-msg {
    border-radius: 10px;
    box-shadow: 0 0 10px #eeeeee;
    width: 100%;
    height: auto;
    padding: 10px 20px;
    font-size: 14px;
    color: #5b5b5b;
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
}

.product-page {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    box-shadow: var(--main-box-shadow);
    border-radius: var(--main-radius);
    padding: 30px 30px 50px;
}

.product-page-title {
    width: 100%;
    justify-content: flex-start;
    display: flex;
    font-size: 24px;
    font-weight: 500;
    border-radius: var(--main-radius);
    color: var(--color-3-dark);
}

.admin-products-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    justify-content: space-between;
}

.btn-switch {
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    border-radius: 5px;
    overflow: hidden;
    font-family: var(--font-1), sans-serif;
    height: min-content;

    & .btn-switch-item {
        padding: 6px 15px;
        font-size: 14px;
        font-weight: 400;
        background-color: #e7e7e7;
        color: rgba(84, 84, 84, 0.8);
        cursor: pointer;

        &.active {
            background-color: var(--color-2);
            color: white;
        }
    }
}


.admin-products-control {
    width: 100%;
    display: flex;
    height: min-content;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    background-color: #f8f8f8;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 30px;
}

.mobile-product-container {
    font-weight: 400;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    padding: 10px;
    background-color: var(--mobile-background-color);
}

@media (max-width: 768px) {
    .product-page {
        padding: 0;
        box-shadow: none;
    }

    .img-block {
        width: 100%;
        height: auto;
        min-height: auto;
        position: relative;
        border-radius: 2px;
    }

    .img-block img {
        width: 100%;
        height: auto;
        border-radius: 2px;

    }

    .img-block .preview {
        width: 100%;
        height: min-content;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        background-color: rgba(194, 194, 194, 0.49);
        padding: 10px 0;

    }

    .img-block .preview img {
        width: 50px;
        height: auto;
        border-radius: 1px;

    }

    .product-page-title {
        margin-top: 10px;
        font-size: 18px;
    }

    .product-info {
        width: 100%;
        padding: 5px 0 15px;
        border: none;
        margin-top: 15px;
    }

    .product-info .description {
        margin-left: 5px;
        font-size: 12px;
    }

    .product-info .title {
        width: auto;
        text-align: left;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .product-info .price {
        font-size: 22px;
    }

    .private-policy-check {
        font-size: 10px;
    }

    .contacts-info h3 {
        font-size: 16px;
    }

    .contacts-info h6, .contacts-info h5 {
        font-size: 12px;
    }

    .contacts-info .links a {
        font-size: 12px;
    }

    .register-form {
        padding: 15px 10px;
    }
}