.edit-tab-page {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    row-gap: 30px;
}

.edit-tab-page .btn{
    font-family: "Montserrat", sans-serif;
    width: auto;
    min-width: 300px;
    max-width: 350px;
    padding: 10px 20px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 16px;
}

.edit-tab-page .instructions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: auto;
    font-size: 14px;
    white-space: pre-line;
    line-height: 25px;
    font-family: "Monospaced", sans-serif;
    box-shadow: 0 0 10px #e8e8e8;
    padding: 10px 20px;
    border-radius: 10px;
}

.tab {
    position: relative;
    padding: 10px 20px 20px;
    box-sizing: border-box;
    box-shadow: 0 0 10px #e3e3e3;
    border-radius: 10px;
}

.tab .form-group {
    margin: 5px 0;
    min-width: 250px;
}

.tab .form-label {
    margin: 5px 0;
}

.tab .form-control {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0;
}

.edit-tab-page .tab .btn{
    max-width: 250px;
    min-width: 150px;
}

.input-banner {
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding: 5px 10px;
    color: #afafaf;
    max-width: 300px;
}

.upload-banner-btn {
    padding: 5px 10px;
    border: 2px solid #d0d0d0;
    border-radius: 5px;
    background-color: white;
    font-family: "Exo 2", sans-serif;
    font-weight: 500;
    font-size: 14px;
    opacity: 0.6;
    color: #545454;
    cursor: pointer;
}

.upload-banner-btn:hover {
    opacity: 1;
}