.resetPassword {
    width: 100%;
    height: auto;
    min-height: 200px;
    box-shadow: 0 0 10px #eeeeee;
    box-sizing: border-box;
    border-radius: 10px;
}

.input-email-form, .input-new-password-form {
    width: 20%;
    min-width: 360px;
    height: auto;
    display: grid;
    padding: 3%;
    grid-template-columns: 1fr;
    row-gap: 20px;
}

.error-label {
    font-size: 14px;
    margin-top: -10px;
    color: #c00000;
}

.info-message {
    display: flex;
    align-items: center;
    gap: 10px;
}

 input {
    height: 25px;
    border: 0;
    padding: 1%;
    border-bottom: 2px solid grey;
    font-family: "Exo 2", sans-serif;
    font-size: 16px;
    font-weight: 500;
}

 input:focus{
    outline: 0;
    outline-offset: 0;
}

.btn-block {
    height: auto;
    width: auto;
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 15px;
    gap: 25px;
}

 .btn-block .rotate-icon {
    width: 25px;
    height: 25px;
    animation: rotate 3s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }
}
.btn-block .icon {
    font-size: 25px;
    color: green;
}

.btn-block button {
    width: 250px;
    padding: 10px 0;
    font-size: 16px;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    color: #8a8a8a;
    background-color: white;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}

.btn-block button:hover {
    border: 1px solid orange;
    color: white;
    background-color: orange;
}

.btn-block button:disabled:hover {
    color: #8a8a8a;
    background-color: white;
    border: 1px solid #d3d3d3;
}