/* cart style */

.cart, .order-checkout {
    width: 100%;
    max-width: 1200px;
    min-height: 300px;
    box-sizing: border-box;
    display: flex;
    height: max-content;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: var(--font-1), sans-serif;
    overflow-x: hidden;
    box-shadow: var(--main-box-shadow);
    padding: 25px 20px;
    border-radius: var(--main-radius);

}


.cart .title {
    width: 100%;
    height: auto;
    align-content: flex-start;
    color: #3a3a3a;
    font-size: 24px;
    font-weight: 400;
}

.cart-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    border-radius: 3px;
    padding: 20px 10px 40px 10px;
    row-gap: 25px;
}

.cart-container h3 {
    font-weight: 400;
    color: #5d5d5d;
    margin-top: 20px;
}

.cart-menu {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 10px;
    width: 100%;
    height: max-content;
    justify-content: space-between;
    border-radius: 3px;
}

.order-checkout .cart-menu {
    margin: 0 1%;
    width: 100%;
}

.order-info {
    position: relative;
    color: #464646;
    width: 100%;
    max-width: 400px;
    height: auto;
    font-weight: 400;
    box-sizing: border-box;
}

.order-info .title {
    padding: 0;
    font-weight: 400;
    font-size: 22px;
    height: auto;
    margin: 10px 0;

}



.countItems {
    padding-left: 10px;
    margin-bottom: 20px;
    font-size: 16px;
}

.checkout-countItems {
    margin-bottom: 20px;
    margin-top: 30px;
    font-size: 22px;
    font-weight: 400;
}


.order-checkout .order-info .promo {
    width: auto;
    height: auto;
    margin-bottom: 20px;
}

.text1 {
    width: auto;
    font-size: 16px;
    margin: 20px 0;
}

.text1 span {
    font-size: 14px;

    font-weight: 400;

}

.text2 {
    margin-top: 25px;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.71);
    color: #ffffff;
}

.promo-title {
    width: 100%;
    font-size: 16px;
    color: #b78f0d;
    margin-bottom: 8px;
    cursor: pointer;
}

.input-promo {
    border: 1px solid #dadada;
    border-radius: 2px;
    box-shadow: none;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    height: auto;
    padding: 5px;
    margin-bottom: 15px;
    font-size: 12px;
}

.input-promo:focus-visible, .input-promocode:focus {
    box-shadow: none;
    outline-style: none;
}

.total-sum {
    margin-top: 40px;
    font-size: 26px;
    padding-top: 10px;
    font-weight: 400;
    border-top: 1px solid #b2b2b2;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
}

.btn-purchase, .btn-purchase-unavailable {
    position: relative;
    cursor: pointer;
    border-radius: 3px;
    box-sizing: border-box;
    width: auto;
    max-width: 460px;
    margin: 40px 0 20px;
    font-weight: 400;
    color: white;
    font-size: 20px;
    background-color: rgba(255, 165, 0, 0.85);
    height: auto;
    padding: 20px;
    text-align: center;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
}

.btn-purchase-unavailable {
    background-color: #757575;
    pointer-events: none;
    opacity: 50%;
}

.btn-purchase:hover {
    opacity: 1;
}

.reset-cart {
    position: relative;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid #c5c5c5;
    font-size: 20px;
    font-weight: 400;
    height: auto;
    width: auto;
    padding: 20px;
    text-align: center;
    margin-top: 15px;
    color: grey;

}

.order-add-info, .register-info {
    width: auto;
    height: auto;
    font-size: 12px;
    font-weight: 400;
    padding: 3%;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 3px;
    margin-top: 30px;
    color: #3f3f3f;
    text-align: justify;


}




.cart-item {
    position: relative;
    color: #000000;
    height: max-content;
    box-sizing: border-box;
    width: 100%;
    font-weight: 400;
    display: flex;
    flex-wrap: nowrap;
    font-size: 15px;
    justify-content: flex-start;

}

.cart-item-info {
    position: relative;
    padding: 0 3%;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
}

.cart-item-available {
    padding: 10px 0;
    font-size: 14px;
    color: #464646;
}

.cart-item-ready {
    font-size: 14px;
    color: #464646;
    padding: 2px 0;
}

.cart-item img {
    width: 150px;
    height: auto;
    max-height: 210px;
    border-radius: 5px;
}

.cart-item-title {
    color: #2f2f2f;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 5px;
}

.cart-item-title:hover {
    color: #00a9ce;
}

.cart-item .quantity {
    height: max-content;
    font-size: 20px;
    font-weight: 400;
    width: auto;
    padding: 5px 5px;
    border-radius: 25px;
    background-color: rgba(229, 229, 229, 0.79);

}

.cart-item .price {
    height: auto;
    width: max-content;
    font-size: 24px;
    font-weight: 400;

}

.cart-item .delete-btn {
    position: absolute;
    height: auto;
    width: auto;
    bottom: 10px;
    right: 27px;
    z-index: 1;
    cursor: pointer;
}

.quantity-btn {
    font-size: 16px;
    margin: 0 20px;
    width: auto;
    background: none;
    border: none;
    color: rgba(145, 0, 131, 0.73);
    cursor: pointer;
}

/* checkout page */



.checkout-container {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 1%;
    display: grid;
    grid-template-columns: 1fr;
    font-weight: 300;
    align-content: flex-start;
}


.order-checkout .checkout-title {
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 24px;
    font-weight: 400;
}

.data-block-2 {
    width: auto;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.data-block-title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    padding: 10px 0;
    color: rgba(0, 0, 0, 0.78);
}


.checkout-container .data-block .form-group, .delivery .data-block .form-group {
    width: 100%;
    max-width: 400px;
}



.form-control-readOnly {
    background-color: #f6f6f6;
    margin-right: 10px;
    border: none;
}

.ref-to-cart {
    margin-top: 20px;
    font-weight: 400;
    font-size: 20px;
    color: #2aa8bd;
}

.choose-type-delivery, .choose-type-payment {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    margin-top: 0;
    margin-bottom: 20px;
    cursor: pointer;
}



.data-block-2 .additional-info {
    position: relative;
    font-size: 18px;
    width: 70%;
    height: auto;
    padding: 20px 20px;
    border-radius: 5px;
    margin-bottom: 30px;
}

.type-delivery, .type-delivery-active, .type-payment, .type-payment-active, .postcard, .postcard-active {
    height: auto;
    width: max-content;
    font-size: 14px;
    border: 1px solid var(--color-2);
    color: var(--color-2);
    border-radius: 3px;
    text-align: center;
    padding: 6px 15px;
    transition: all 100ms ease;
    font-weight: 400;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.checkout-container .form-group .form-label {
    font-size: 14px;
    font-weight: 400;
}

.delivery-info {
    position: relative;
    font-size: 14px;
    width: auto;
    height: auto;
    background-color: #f1f1f1;
    line-height: 20px;
    padding: 1% 2%;
    color: #494949;
    font-weight: 400;
    border-radius: 2px;
    margin-bottom: 5px;
    text-align: left;
}

.delivery-info span{
    font-weight: 500;
}

.delivery-free-info {
    font-size: 14px;
    color: #484848;
}

.delivery-free-info span {
    font-weight: 500;
    font-size: 14px;
}

.delivery-cost {
    font-size: 16px;
    font-weight: 400;
}

.self {
    box-sizing: border-box;
    position: relative;
    padding: 2%;
    width: 100%;
    height: auto;
    font-size: 20px;
    border: 1px solid #dadada;
    border-radius: 2px;
}

.link-to-page {
    font-size: 14px;
}

 h3 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
}

 h4 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    background: #d0d0d0;
    padding: 5px 10px;
}

 h5 {
    margin-top: 5px;
    font-weight: 400;
}




.order-checkout .order-item-info h3 {
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 15px;
}

 h6 {
    margin-top: 30px;
    font-weight: 300;
}

.order-checkout .order-info {
    height: auto;
    margin: 0;
}

.type-delivery-active, .type-payment-active {
    background-color: var(--color-2);
    color: white;
}


.data-block-2 .total-sum {
    margin-top: 0;
    padding-top: 0;
    font-size: 25px;
    font-weight: 500;
}

.data-block-2 .countItems {
    margin-bottom: 0;
}

.accept-btn, .accept-btn-unavailable {
    width: auto;
    height: auto;
    font-size: 25px;
    border-radius: 2px;
    padding: 15px 30px;
    text-align: center;
    background-color: orange;
    color: white;
    cursor: pointer;
    font-weight: 400;
    z-index: 100;
    pointer-events: all;
}

.accept-btn-unavailable {
    background-color: #757575;
    pointer-events: none;
    opacity: 50%;
}

.accept-btn:hover {
    opacity: 70%;
}

.info-message {
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    text-justify: auto;
    font-weight: 400;
    position: relative;
    margin: 50px 5px;
}


/* DateTimePicker */

.date-time-container, .date-time-container-inactive {
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    margin-top: 10px;
}

.date-time-container-inactive {
    pointer-events: none;
    opacity: 0.5;
}

.date-time-container .date {
    width: 300px;
}

.postcard {
    margin: 30px 0;
}

.postcard-active {
    color: white;
    background-color: var(--color-2);
    margin: 30px 0;
}


@media (max-width: 768px) {
    .cart, .order-checkout {
        box-shadow: none;
        padding: 15px 2px;
        background-color: var(--mobile-background-color);

        & .data-block-2 {
            padding: 0 5px;
        }

        & .checkout-title {
            padding: 5px;
            margin: 0;
            font-size: 20px;
        }

    }

    .cart-menu {
        padding: 20px 5px;
    }

    .cart-container, .checkout-container {
        padding: 5px;
    }

    .cart .title {
        font-size: 20px;
        padding: 0 5px;
    }

    .order-info .title {
        font-size: 18px;
    }

    .cart-item {
        grid-template-columns: 1fr 1fr;
        align-content: flex-start;
        align-items: flex-start;
        font-size: 12px;
    }

    .cart-item-ready {
        font-size: 12px;
        width: 100%;
        padding: 0;
    }

    .cart-item .price {
        font-size: 16px;
        margin-top: 10px;
    }

    .cart-item img {
        width: 100px;
        height: auto;
        object-fit: scale-down;
    }

    .quantity-btn {
        font-size: 16px;
    }

    .cart-item .delete-btn {
        font-size: 16px;
        bottom: 0;
        top: auto;
        right: 0;
    }

    .cart-item-title {
        font-size: 14px;
        color: black;
        margin-top: 0;
        margin-bottom: 5px;
    }

    .data-block-title {
        font-size: 16px;
    }

    .self {
        font-size: 16px;
    }

    h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    h6 {
        margin-top: 15px;
    }

    .link-to-page {
        font-size: 12px;
    }

    .delivery-info {
        font-size: 12px;
    }

    .checkout-countItems {
        font-size: 18px;
    }

    .order-checkout .order-item-info h3 {
        font-size: 12px;
    }

    .total-sum {
        font-size: 22px;
        font-weight: 400;
        margin-top: 25px;
    }

    .btn-purchase, .btn-purchase-unavailable, .reset-cart {
        font-size: 16px;
    }

    .delivery-cost {
        margin-left: 5px;
        font-size: 12px;
    }
}

