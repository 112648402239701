@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400&display=swap');

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: auto;
    z-index: 100;
    font-family: var(--font-1), sans-serif;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 5px rgba(162, 162, 162, 0.87);
    overflow: hidden;
}


.nav-item {
    position: relative;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    width: auto;
    max-width: 1200px;
    margin: 0 auto 25px;
    white-space: nowrap;
    border-radius: 10px;
    justify-items: flex-start;
    color: rgb(42, 42, 42);
    height: auto;
    max-height: 300px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}

.nav-item img {
    position: relative;
    width: 400px;
    height: auto;
    margin-right: 5px;
    color: #3a3a3a;
    border-radius: 10px;
}

.nav-item-menu {
    width: auto;
    box-sizing: border-box;
    position: relative;
    padding: 0 15px;
}

.nav-item-menu .title {
    position: relative;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    height: auto;
    color: #bb992d;
    text-shadow: 0 0 1px rgba(187, 153, 45, 0.47);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
    transition: color 0.2s ease-in-out;
    justify-content: start;
    opacity: 0.75;
}

.nav-item-menu .title:hover {
    opacity: 1;
}

.nav-item-menu .links {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 0 45px;
    padding: 1px 0 1px;
    height: auto;
    position: relative;
}

.nav-item-menu .links ul {
    margin-top: 0;
    list-style-position: inside;
    -moz-column-count: 2; /* Firefox */
    -webkit-column-count: 2; /* Safari and Chrome */
    column-count: auto;
    list-style-type: none;
    height: 221px;
    column-width: 300px;
}

.nav-item-menu .links ul li {
    font-size: 14px;
    color: #7c7c7c;
    cursor: pointer;
    font-family: var(--font-1), sans-serif;

    font-weight: 400;
    background-color: #f8f8f8;
    padding: 5px 15px;
    margin-bottom: 10px;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #515151;
        background-color: #eaeaea;
        box-shadow: 0 0 5px #eaeaea;
    }
}



.mobile-navbar {
    width: 100%;
    justify-content: space-between;
    padding: 3%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    bottom: 0;
    top: auto;
    background-color: white;
    height: auto;
    box-shadow: 0 0 10px grey;
}
.mobile-navbar.active {
    box-shadow: none;
}

.links .sub-links {
    display: grid;
    grid-template-columns: 1fr;
}

.sub-links .sub-link {
    font-size: 16px;
    width: 100%;
    margin-bottom: 5px;
    cursor: pointer;
    color: black;
}

.sub-links .sub-link:hover {
    color: black;
}

.mobile-navbar .menu-btn {
    font-size: 25px;
    color: #000000;

}


.mobile-navbar .menu.active {
    transform: translateY(100%);
    height: auto;
    opacity: 1;
    visibility: visible;
}

.menu-list {
    list-style-type: none;
    color: black;
    margin: 0;
    padding: 0;
    width: 100%;

}

.menu-list li {
    margin-top: 15px;
}

.menu-item {
    text-decoration: none;
    color: #4f4f4f;
    font-size: 20px;
    font-weight: 500;
}

