.modal {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.19);
    position: fixed;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition: 0.3s;
    z-index: 1100;
}


.modal-message {
    height: auto;
    width: 15%;
    min-width: 200px;
    position: fixed;
    right: 1%;
    bottom: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition: 0.3s;
    z-index: 500;
    color: #e8dbe8;
}




.modal__content {
    background-color: white;
    box-sizing: border-box;
    width: auto;
    min-width: 200px;
    max-width: 700px;
    height: auto;
    max-height: 600px;
    padding: 20px 20px;
    position: relative;
    display: grid;
    min-height: 200px;
    grid-template-columns: 1fr;
    margin: 100px auto;
    border-radius: var(--main-radius);
    transform: translateX(-300%);
    transition: all 0.4s ease-in-out;
    box-shadow: 5px 5px 35px #b6b6b6;

}

.modal-message-content {
    font-family: "Nunito", sans-serif;
    width: 100%;
    height: auto;
    overflow: hidden;
    transform: translateY(200%);
    transition: all 0.4s ease-in-out;
    background-color: #343434;
    border-radius: var(--main-radius);
    padding: 20px;
}

.modal-message-content .message {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    text-align: center;

}

.modal-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: #f5f5f5;
    margin-bottom: 10px;
    padding: 10px;
    justify-content: center;
    box-sizing: border-box;
    font-family: var(--font-1), sans-serif;

    & .btn {
        width: auto;
        font-size: 12px;
        background-color: #565656;
        margin: 0;
        text-align: center;
        height: auto;
        align-content: center;
        font-weight: 500;
    }

    & .info-msg {
        text-align: start;
        box-shadow: none;
        font-size: 10px;
        padding: 5px;
        margin: 0;
    }
}

.modal-item .input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;

}

.modal-item .input__wrapper {
    width: 180px;
    margin-top: 0;
    font-size: 12px;
}

.modal-item .input__file-icon-wrapper {
    width: 30px;
    height: 30px;
}

.modal-item .input__file-button {
    height: 30px;
    font-size: 10px;
}


.modal-item .preview-container {
    background-color: #f5f5f5;
    gap: 5px;
    padding: 0;
    margin: 0;
    display: flex;
    align-content: center;
    align-items: center;

    & .preview-img {
        width: 47%;
        height: auto;
        border-radius: 0;
        background-color: #f5f5f5;

    }

    & .preview-img img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
}

.modal-item label {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #494949;
    margin-bottom: 10px;
}

.modal-item textarea {
    box-sizing: border-box;
    min-width: 100%;
    min-height: 200px;

}

.modal-control {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    margin-bottom: 10px;

    & .close-btn {
        background-color: #3a3a3a;
        border-radius: 2px;

        color: white;
        font-weight: 500;
        width: min-content;
        padding: 0 6px 2px;
        height: min-content;
        min-height: auto;
        margin: 0;
        cursor: pointer;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }
}

.modal.active {
    opacity: 1;
    pointer-events: all;
    margin-right: 20px;
}

.modal.active .modal__content {
    transform: translateY(0);
}


.modal-message.active {
    opacity: 1;
    z-index: 1000;
    pointer-events: all;
}


.modal-message.active .modal-message-content {
    transform: translateX(0);
}

.form-group input {
    margin-bottom: 5px;
}

.img-preview {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.img-preview .container {
    margin-top: 10px;
    width: 200px;
    height: auto;
    position: relative;
    object-fit: cover;
}

.img-preview img {
    margin-top: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
    position: relative;
}

.img-preview .img-text {
    position: absolute;
    width: auto;
    font-size: 35px;
    right: 5%;
    color: #ffffff;
    top: 20px;
    opacity: 50%;
}

.img-preview .img-text:hover {
    opacity: 1;
    color: #b60000;
}

.textarea {
    position: relative;
    width: auto;
    resize: vertical;
    height: auto;
    min-height: 250px;
    box-sizing: border-box;
    font-family: Monospaced, sans-serif;
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
}

.modal-title {
    color: white;
    font-size: 25px;
    padding: 10px 10px;
    text-align: center;
    border-radius: 5px;
    background-color: #79a988;
    margin: 0 0 30px 0;
    font-weight: 500;
}


.form-group {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin: 10px 0;
    align-items: center;
    box-sizing: border-box;
}


.select-field {
    font-size: 14px;
    font-weight: 500;
}

.form-label {
    position: relative;
    color: #3b3b3b;
    width: auto;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    box-sizing: border-box;
}

.form-control, .form-control-readOnly {
    position: relative;
    font-size: 14px;
    padding: 10px 15px;
    font-family: var(--font-1), sans-serif;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    border-radius: 3px;
    font-weight: 400;
    border: none;
    outline-style: none;
    background-color: #f1f1f1;
    color: #5e5e5e;

}



.invalid-feedback {
    font-size: 12px;
    margin-top: 10px;
}

.btn {
    margin: 20px auto 0;
    height: auto;
    width: 100%;
    padding: 10px 40px;
    background-color: #8fab99;
    color: white;
    font-weight: 500;
    font-size: 16px;
    border-radius: 2px;
    border: none;
    opacity: 0.75;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.btn:hover {
    opacity: 1;
}

.ref {
    margin-top: 30px;
    font-weight: 500;
    font-size: 12px;

}

.register-ref {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
}

a {
    font-size: 14px;
    text-decoration: none;
    color: #31a0dc;
}

.login-header {
    font-family: "Sofia Sans Semi Condensed", sans-serif;
    width: max-content;
    margin: 0 auto 30px;
    font-weight: 600;
    font-size: 22px;
    color: rgba(49, 49, 49, 0.81);
}

/* registration page */

.registration-info {
    background-color: #8fab99;
    padding: 10px 10px;
    width: auto;
    margin: 50px auto;
    height: auto;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    border-radius: 5px;
    text-align: justify;
}

/* mobile-menu edit page */

.menu-item-edit {

}

.error {
    font-size: 13px;
    font-weight: 300;
    color: rgba(166, 31, 31, 0.65);
    padding: 2px 5px;
}


.login-form {
    position: relative;
    padding: 30px;
    width: 300px;
    display: grid;
    grid-template-columns: 1fr;
    box-sizing: border-box;
    justify-content: center;
}

.login-form .form-group {
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    box-sizing: border-box;
}


.rating-field {
    display: flex;
    flex-wrap: nowrap;
    gap: 2px;
    cursor: pointer;

}

/* UserModal page */



.user-modal-row {
    background-color: #f8f8f8;
    color: #686868;
    max-width: 300px;
    padding: 10px 15px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    & .common-btn {
        width: 100%;
    }

    & label {
        font-size: 14px;
        font-weight: 500;
    }

    & div {
        font-size: 14px;
        font-weight: 400;
    }
}

@media (max-width: 768px) {

    .modal-message {
        width: 50%;
        top: 40%;
        left: calc(50% - max(100px, 25%));
        right: auto;
        bottom: auto;
    }

    .modal-message-content {
        transform: translateY(0);
    }

    .textarea {
        min-height: 400px;
    }
}