.filter-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    padding: 10px 0;
}

.mobile-filter-container {
    width: auto;
    position: relative;
    height: max-content;
    font-family: var(--font-1), sans-serif;
    font-size: 12px;
    font-weight: 400;
    border-radius: var(--main-radius);
    padding: 7px 12px 8px;
    background-color: #f6f6f6;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: var(--color-1-textColor);
    gap: 10px;

}

.filter-block {
    position: relative;
    height: auto;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    row-gap: 10px;
    column-gap: 10px;
}

@keyframes slideme {
    0% {
        left: -30px;
        margin-left: 0px;
    }
    30% {
        left: 110%;
        margin-left: 80px;
    }
    100% {
        left: 110%;
        margin-left: 80px;
    }
}

.filter-item {
    color: rgba(133, 133, 133, 0.82);
    background-color: #f6f6f6;
    padding: 7px 15px 8px;
    border-radius: var(--main-radius);
    font-family: var(--font-1), sans-serif;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    width: auto;
    transition: 0.2s linear;
}

.filter-item.active {
    color: #ffffff;
    background-color: var(--color-2);

    overflow: hidden;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -200px;
        width: 60px;
        height: 100px;
        background-color: #fff;
        filter: blur(30px);
        transform: skewX(30deg) translateZ(0);
        transition: 1s;
        animation-name: slideme;
        animation-duration: 3s;
        animation-delay: 0.05s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
    }


}

.filter-item:hover {
    opacity: 1;
}

@media (max-width: 768px) {
    .mobile-filter-container .rdn-drop,
    .mobile-filter-container .rdn-selection {
        right: auto;
        left: -12px;
        top: 30px;
        min-width: 200px;
    }

    .mobile-filter-container .rdn-control {
        width: auto;
    }

    .mobile-filter-container .rdn-control {
        font-size: 12px;
        color: var(--color-1-textColor);
    }
}