.create-bouquet {
    width: 100%;
    height: auto;
    font-size: 24px;
    box-sizing: border-box;
    display: flex;
    text-align: center;
    margin: 70px auto 0;
    font-family: "Sofia Sans Semi Condensed", sans-serif;
    overflow: hidden;
    border-radius: 5px;
    background-color: var(--color-2);
    color: #ffffff;
    font-weight: 500;
    box-shadow: 0 0 10px #e3e3e3;
}

.create-bouquet img {
    position: relative;
    width: 60%;
    height: auto;
    border-radius: 2px;
    object-fit: cover;
}



.create-bouquet .line {
    width: 150px;
    margin: 30px auto;
    border-bottom: 2px solid white;
}

.create-bouquet p {
    font-size: 14px;
    margin-bottom: 40px;
    font-weight: 300;
}

.create-btn {
    font-size: 14px;
    position: relative;
    margin: auto;
    width: max-content;
    background-color: var(--color-1-textColor);
    padding: 10px 20px 12px 20px;
    border-radius: 5px;
    font-weight: 400;
    cursor: pointer;
}

.create-btn:hover {

    opacity: 1;
}

.create-bouquet .text-block {
    position: relative;
    text-align: center;
    width: auto;
    padding: 50px 20px;
    box-sizing: border-box;
    background-color: rgba(143, 171, 153, 0);
}