
.nav-list__link {
    position: relative;
    width: max-content;
    transition: opacity 0.2s ease-in;
    text-decoration: none;
    overflow: hidden;
}

.logo {
    position: relative;
    height: 90px;
    margin-top: 5px;
    width: auto;
    object-fit: cover;
    background-color: white;
    overflow: hidden;
}

header {
    position: fixed;
    top: 0;
    width:100%;
    height: 100px;
    font-family: "Sofia Sans Semi Condensed", sans-serif;
    max-height: 100px;
    box-sizing: border-box;
    z-index: 1000;
    transition: all 0.05s ease-in-out;
    background-color: rgb(255, 255, 255);
    align-content: center;
    box-shadow: var(--main-box-shadow);

}

.header-nav {
    position: relative;
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    align-content: center;
    justify-content: flex-end;

}

.btn-common {
    position: relative;
    cursor: pointer;
    font-weight: 400;
    color: #1c1c1c;
    width: auto;
    padding: 0;
    height: auto;
    text-align: center;
    transition: all 300ms ease;
    font-family: var(--font-1), sans-serif;
    opacity: 0.7;
}

.menu-item-icon {
    font-size: 20px;
    margin: 10px auto 5px;
    color: #434343;
    padding: 7px 8px;
    border-radius: 5px;
    background-color: rgba(239, 239, 239, 0.63);
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: rgba(223, 223, 223, 0.76);
        box-shadow: 0 0 3px rgba(223, 223, 223, 0.76);
    }
}


.icon-label {
    font-size: 14px;
    color: #424242;
    padding-bottom: 5px;
    font-family: var(--font-1), sans-serif;
    box-sizing: border-box;
}

.btn-common:hover , .btn-cart:hover{
    color: #000000;
    opacity: 1;

}
.btn-common:hover .menu-item-icon, .btn-cart:hover .menu-item-icon {
    opacity: 1;
}

header .logo-nav-bar {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin: auto;
    align-content: center;
    justify-content: space-between;
    z-index: 2000;
}


.presentation {
    position: relative;
    width: 100%;
    height: auto;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}

.presentation img {
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 5px;

}


.icon-style, .icon-style-cart, .icon-style-footer {
    font-size: 25px;
    margin-left: 20px;
    color: #464646;
    cursor: pointer;
    opacity: 75%;
    transition: transform 500ms ease, opacity 500ms ease;

}

.icon-style:hover, .icon-style-cart:hover {
    transform: scale(1.1);
    opacity: 100%;
}


.catalog-menu {
    height: 100px;
    width: auto;
    justify-content: start;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

}

.catalog-menu .btn-block {
    display: flex;
    height: 100%;
    margin: 0;

    flex-wrap: nowrap;
    box-sizing: border-box;
    align-content: center;
    align-items: center;

    & .btn {
        height: min-content;
        width: auto;
        margin: 0;
        align-items: center;
        justify-content: center;
        font-family: var(--font-1), sans-serif;
        border-radius: 12px;
        background: var(--color-1);
        text-shadow: 0 0 1px #577663;
        display: flex;
        flex-wrap: nowrap;
        padding: 10px 20px;
        font-size: 18px;
        font-weight: 400;
        box-sizing: border-box;
        color: var(--color-1-textColor);
        cursor: pointer;
        box-shadow: 0 0 3px var(--color-1);

        overflow: hidden;
        position: relative;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: #a7ccad;
            box-shadow: 0 0 5px #B8D9BEFF;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -200px;
            width: 60px;
            height: 100px;
            background-color: #fff;
            filter: blur(30px);
            transform: skewX(30deg) translateZ(0);
            transition: 1s;
            animation-name: slideme;
            animation-duration: 3s;
            animation-delay: 0.05s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
        }
    }

    & .btn.main {
        background-color: var(--color-3);
        color: white;
        text-shadow: 0 0 1px #ffffff;
        box-shadow: 0 0 3px var(--color-3);


        &:hover {
            background: #e98af3;
            box-shadow: 0 0 5px #e6aced;
        }
    }
}

.catalog-container {
    position: fixed;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    height: auto;
    max-height: 90vh;
    z-index: -3;
    overflow-y: scroll;
    top: 100px;
}

.menu-container {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin:0 auto 10px;
    padding: 20px 0 0 0;
    box-sizing: border-box;
}

.modal__cart__window {
    position: absolute;
    top: 72px;
    right: -10px;
    width: 350px;
    height: auto;
    max-height: 500px;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-radius: 0 0 5px 5px;
    z-index: 2000;
    background-color: rgb(255, 255, 255);
    padding: 20px 20px;
    box-sizing: border-box;
    font-family: var(--font-2), sans-serif;
    cursor: auto;
    box-shadow: 0 10px 10px #e8e8e8;

    & .title {
        color: rgba(51,51,51,0.76);
        font-family: var(--font-1), sans-serif;
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 16px;
    }

    & .common-btn {
        width: 100%;
        margin-top: 10px;
    }

    & .total-sum {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.73);
        font-weight: 500;
        margin-top: 15px;
    }

    & .modal__cart_products_container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        box-sizing: border-box;

        & .modal__cart_item {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: nowrap;

            & .modal__cart_item_description {
                width: 100%;
                height: 100%;
                position: relative;
                flex-wrap: wrap;
                display: flex;
                font-size: 14px;
                font-weight: 500;
                padding-left: 10px;
                color: #3b3b3b;
                justify-content: flex-start;

                & .delete-btn {
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    cursor: pointer;
                }
            }

            & img {
                width: 100px;
                height: auto;
            }
        }
    }
}


/* MOBILE VERSION */

.mobile-header {
    width: 100%;
    height: auto;
}

.mobile-header img {
    width: auto;
    height: 80px;
    object-fit: cover;
}

.banners {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    overflow-x: scroll;
    position: relative;
    box-sizing: border-box;
    padding: 0 10px;
}

.banners img {
    box-sizing: border-box;
    width: auto;
    max-width: 100%;
    height: auto;
}